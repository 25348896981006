<template>
    <div>
        <NewsCenter_Component></NewsCenter_Component>
        <Footer_Compontent></Footer_Compontent>
    </div>
</template>
<!-- CargoTracking -->
<script>
import NewsCenter_Component from '../../components/NewsCenter/NewsCenter_Component.vue'
import Footer_Compontent from '../../components/Footer_Compontent.vue'
export default {
    name: "NewsCenter",
    components: { NewsCenter_Component, Footer_Compontent },
    data() {
        return {

        }
    },
}
</script>

<style lang="scss" scoped>
.footer {
    margin-top: 80px;
}
</style>