<template>
    <div class="newCenterBox">
        <div class="bgc"></div>
        <div class="newCenter">
            <div class="newCenter_Title">
                <p>新闻中心</p>
            </div>
            <div class="newCenter_Content">
                <ul>
                    <li v-for="(item, index) in newsWenList" :key="index" @click="handleNews(item.id)">
                        <div class="img">
                            <img :src="'https://tu.ceecexpo.com/upload/' + item.images" alt="">
                        </div>
                        <div class="right">
                            <p>{{ item.title }}</p>
                            <p>{{ item.pushTime.split(' ')[0] }}</p>
                            <p>{{ item.content }}</p>
                        </div>
                    </li>
                </ul>
            </div>

        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { newsList, newsDetail } from '../../tools/ajax';
export default {
    name: "NewsCenter_Component",
    data() {
        return {
            newsDetailList: [],
            newsWenList: [],
        }
    },
    async created() {

        await newsList({
            responseType: 'arraybuffer' // 这里必须加上类型，以便拿到数据后进行转换
        }).then(res => {
            this.newsWenList = res.result.records;
            console.log(this.newsWenList);

            let reg = new RegExp('<[^<>]+>', 'g')
            var textList = this.newsWenList;
            textList.forEach((v, i) => {
                this.newsWenList[i].content = v.content.replace(reg, "");
                this.newsWenList[i].content = this.newsWenList[i].content.replace(/&ldquo;/ig, "");
                this.newsWenList[i].content = this.newsWenList[i].content.replace(/&middot;/ig, "");
                this.newsWenList[i].content = this.newsWenList[i].content.replace(/&rdquo;/ig, "");
            })
        });
    },
    mounted() {

    },
    methods: {
        handleNews(id) {
            this.$router.push({
                path: "/NewsCenterDetail",
                query: {
                    id: id
                }
            })
        }
    },
}
</script>

<style lang="scss" scoped>
.bgc {
    position: absolute;
    left: 0;top: 0;
    width: 100vw;
    // height: 100vh;
    background-color: #f6f6f6;
    z-index: -1;
}

.newCenterBox {
    width: 100%;
    margin-top: 48px;


    .newCenter {
        width: 1200px;
        margin: 0 auto;

        .newCenter_Title {
            height: 30px;
            font-size: 30px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #2B2B2B;
            line-height: 24px;
        }

        .newCenter_Content {
            ul {
                margin-top: 40px;

                li {
                    cursor: pointer;
                    display: flex;
                    // height: 281px;
                    padding-bottom: 24px;
                    border-bottom: 1px solid #999999;
                    margin-top: 24px;
                    // border: 1px solid #707070;

                    .img {
                        min-width: 386px;
                        height: 257px;
                        background: #FFFFFF;
                        border-radius: 4px 4px 4px 4px;
                        overflow: hidden;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .right {
                        margin-left: 40px;

                        p:nth-child(1) {
                            font-size: 24px;
                            font-weight: bold;
                            color: #333333;
                        }

                        p:nth-child(2) {
                            font-size: 14px;
                            font-weight: 400;
                            color: #999999;
                            margin-top: 16px;
                        }

                        p:nth-child(3) {
                            font-size: 18px;
                            font-weight: 400;
                            color: #333333;
                            margin-top: 25px;
                            overflow: hidden;
                            display: -webkit-box;
                            /*弹性伸缩盒子*/
                            -webkit-box-orient: vertical;
                            /*子元素垂直排列*/
                            -webkit-line-clamp: 5;
                            /*可以显示的行数，超出部分用...表示*/
                            text-overflow: ellipsis;
                            /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
                        }
                    }
                }

                li:last-child {
                    border-bottom: none;
                }
            }
        }
    }
}
</style>